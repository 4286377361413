@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar2::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    /* background: #f7f4ed; */
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* Firefox */
    scrollbar-width: none;
  }
}

* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

.app--container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.app--container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-page-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --foreground-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

.side-bar {
  width: 8rem;
  height: 100vh;
  position: fixed;
}

.hidden--cell {
  display: none;
}

@media screen and (max-width: 780px) {
  .side-bar {
    display: none !important;
  }

  .main-area {
    margin-left: 0;
  }
}

.f-date-- {
  font-size: 12px;
  margin-bottom: -4.2px;
  padding: 0;
  text-transform: uppercase;
  color: rgb(131, 129, 129);
}

.day-month {
  font-size: 9px;
}

.active--btn {
  color: var(--accent-9);
  font-weight: 600;
}

.app-link {
  text-decoration: none;
  color: gray;
}

.text-content {
  --tw-text-opacity: 1;
  color: var(--accent-9);
  font-size: 0.8rem;
  font-weight: 300;
  margin-bottom: -4px;
}

.text--info {
  --tw-text-opacity: 1;
  color: rgb(139, 139, 143);
  font-size: 0.6rem;
  font-weight: 300;
  margin-bottom: -3px;
}

.carousel-item {
  box-sizing: border-box;
}

.wth--33 {
  width: 33.3333%;
}

.box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.select--input {
  padding: 4px;
  background-color: rgb(20, 20, 20);
  border: 1px rgb(65, 63, 63) solid;
  border-radius: 3px;
  font-size: 14px;
  outline: none;
}

.nav--bar {
  z-index: 5;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  background-color: var(--color-page-background);
}

@layer utilities {
  .nav--link {
    @apply text-zinc-500 hover:text-zinc-400 transition-colors;
    text-decoration: none;
  }
}

.nav--link2 {
  text-decoration: none;
}

.nav--link2:hover {
  text-decoration: underline;
  color: var(--accent-9);
}

.text--xs {
  font-size: 0.7rem;
}

.text--2xs {
  font-size: 0.6rem;
}

.text--3xs {
  font-size: 0.55rem;
}

.text--accent {
  color: var(--accent-9);
}

.text--light {
  color: rgb(161, 157, 157);
}

.text--dark {
  color: rgb(12, 12, 12);
}

.bg--light {
  background-color: rgba(192, 189, 189, 0.836);
}

.bg--dark {
  background-color: rgba(0, 0, 0, 0.452);
}

.box--shadow {
  box-shadow: 0px 0px 5px 1px rgba(125, 121, 121, 0.3);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(125, 121, 121, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(125, 121, 121, 0.3);
}

.bg--2 {
  background-color: rgba(0, 0, 0, 0.452);
}

.bg--1 {
  background-color: var(--accent-3);
}

.comment--section {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  /* visibility: collapse; */
  height: 0;
  transition: height 0.4s ease-in-out;
}

.comment--section::-webkit-scrollbar {
  display: none;
}

.comment--active {
  /* visibility: visible; */
  height: 300px;
}

.team-stats text {
  text-anchor: end;
}
