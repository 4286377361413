:root {
  --bg: rgba(0, 0, 0, 0.6);
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#playground-app {
  padding: 1em;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.container {
  position: relative;
  // width: 100%;
  /* padding: 1rem; */
}

.geo-info {
  position: absolute;
  width: 100%;
  bottom: 0;
  color: white;
}

.geo-wrapper {
  display: flex;
  gap: 0.5rem;
  margin: 0.5rem 1rem;
}

.address {
  border-radius: 10px;
  margin: 0;
}

.geo-details {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  font-weight: 100;
  font-size: 10px;
  // height: 80px;
}

.date-time {
  background-color: var(--bg);
  border-radius: 5px;
  flex-grow: 1;
  width: calc(100% - 1rem);
  padding: 0.3rem;
}

.line {
  border-top: 0.5px solid rgb(126, 125, 125);
  padding: 0;
  /* margin: 0.3rem 0; */
}

.geo-wrapper img {
  width: 80px;
  height: 80px;
  background-color: rgba(29, 29, 29, 0.452);
}
.gps-coord {
  font-weight: 500;
}
